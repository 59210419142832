import React from 'react';
import PropTypes from 'prop-types';
import { ScreenClassRender } from 'react-grid-system';
import classNames from 'classnames/bind';
import styles from './PageTitleBar.module.scss';

const cx = classNames.bind(styles);

const PageTitleBar = ({ title, titleLangKor, backgroundImage }) => (
  <ScreenClassRender render={(screenClass) => {
    const isMobile = ['xs', 'sm', 'md'].includes(screenClass);
    const height = isMobile ? 160 : 320;

    return (
      <div className={cx(['container', { paddingTop: isMobile }])} style={{ height, backgroundImage: `url(${backgroundImage})` }}>
        <div className={cx([{ mobileFont: isMobile }, { titleKorStyle: titleLangKor }, { titleEngStyle: !titleLangKor }])}>{title}</div>
      </div>
    );
  }} />
);

PageTitleBar.propTypes = {
  title: PropTypes.string.isRequired,
  titleLangKor: PropTypes.bool,
  backgroundImage: PropTypes.string.isRequired,
};

PageTitleBar.defaultProps = {
  titleLangKor: false,
};

export default PageTitleBar;