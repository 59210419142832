import React from 'react';

import EmailSecurity from 'components/docs/EmailSecurity';
import PageTitleBar from 'components/misc/PageTitleBar';

const EmailSecurityPage = () => (
  <div>
    <PageTitleBar
      title="이메일무단수집거부"
      titleLangKor
      backgroundImage={require('../assets/images/banner/agreement.jpg')}
    />
    <EmailSecurity />
  </div>
);

export default EmailSecurityPage;
